define("additive-mi/serializers/budget", ["exports", "additive-mi/serializers/application", "lodash.clonedeep", "additive-mi/utils/currency-serialization"], function (_exports, _application, _lodash, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BudgetSerializer extends _application.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      super.normalizeResponse(...arguments);

      // check if payload contains array or single content
      const firstKey = Object.keys(payload)[0];
      const contents = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];

      // transform currency properties
      contents.forEach(content => {
        if (content.value) {
          content.value = (0, _currencySerialization.centsToAmount)(content.value);
        }
        if (content.surcharge) {
          content.surcharge = Number.parseFloat(content.surcharge * 100).toFixed(2);
        }
        const keys = ['googleAds', 'tiktok', 'meta', 'other'];
        keys.forEach(key => {
          const value = content.values.find(item => item.key === key)?.value || 0;
          if (key === 'other') {
            content.otherCosts = (0, _currencySerialization.centsToAmount)(value);
          } else {
            content[key] = {
              value: (0, _currencySerialization.centsToAmount)(value)
            };
          }
        });
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
    serialize(snapshot, options) {
      let json = super.serialize(snapshot, options);
      const jsonCopy = (0, _lodash.default)(json);
      if (jsonCopy.value) {
        jsonCopy.value = (0, _currencySerialization.amountToCents)(jsonCopy.value);
      }
      if (jsonCopy.surcharge) {
        jsonCopy.surcharge = Number.parseFloat(jsonCopy.surcharge / 100).toFixed(4);
      }
      const keys = ['googleAds', 'tiktok', 'meta', 'otherCosts'];
      jsonCopy.values = [];
      keys.forEach(key => {
        if (key === 'otherCosts') {
          const value = jsonCopy[key] ? jsonCopy[key] : 0;
          jsonCopy.values.push({
            key: 'other',
            value: (0, _currencySerialization.amountToCents)(value)
          });
        } else {
          const value = jsonCopy[key].value ? jsonCopy[key].value : 0;
          jsonCopy.values.push({
            key,
            value: (0, _currencySerialization.amountToCents)(value)
          });
        }
      });
      return jsonCopy;
    }
  }
  _exports.default = BudgetSerializer;
});