define("additive-mi/mirage/routes/settings/budget-settings", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BudgetSettingRoutes = void 0;
  const BudgetSettingRoutes = self => {
    self.get('/settings/monthly-fees/budgets', schema => {
      return buildPayload(schema);
    });
    self.get('/settings/monthly-fees/budgets/automated-import', () => {
      return {
        active: false,
        excludedCampaigns: [{
          id: 'meta-123343',
          type: 'meta',
          name: 'meta-campaign-name'
        }, {
          id: 'tiktok-1233243243',
          type: 'tiktok',
          name: 'tiktok-campaign-name'
        }, {
          id: 'google-ad-1312312312323',
          type: 'googleAds',
          name: 'tiktok-campaign-name'
        }]
      };
    });
    self.put('/settings/monthly-fees/budgets/automated-import', (_, request) => {
      return request.requestBody;
    });
    self.get('/settings/monthly-fees/budgets/automated-import/campaigns', (_, request) => {
      const {
        type
      } = request.queryParams;
      if (type === 'meta') {
        return {
          campaigns: [{
            name: 'test campaign',
            id: '312312312312',
            type: 'meta'
          }, {
            name: 'test campaign 2',
            id: '3888886636727',
            type: 'meta'
          }]
        };
      } else if (type === 'tiktok') {
        return {
          campaigns: [{
            name: 'test campaign',
            id: '4324324',
            type: 'tiktok'
          }, {
            name: 'test campaign 2',
            id: '3888886636727',
            type: 'tiktok'
          }]
        };
      } else {
        return {
          campaigns: [{
            name: 'test campaign',
            id: '3243243243242',
            type: 'googleAds'
          }, {
            name: 'test campaign 2',
            id: '3888886636727',
            type: 'googleAds'
          }]
        };
      }
    });
    self.post('/settings/monthly-fees/budgets', (schema, request) => {
      const requestBody = JSON.parse(request.requestBody);
      requestBody.startsAt = new Date(requestBody.startsAt);
      const budgetWithSameStartsAt = schema.budgets.findBy(budget => budget.startsAt.getMonth() === requestBody.startsAt.getMonth() && budget.startsAt.getFullYear() === requestBody.startsAt.getFullYear());
      if (budgetWithSameStartsAt) {
        return new _emberCliMirage.Response(422, {}, {
          errors: {
            startsAt: ['Startdatum ist bereits vorhanden.']
          }
        });
      }
      const newBudget = schema.budgets.create({
        ...requestBody
      });
      return newBudget;
    });
    self.put('/settings/monthly-fees/budgets/:id', (schema, request) => {
      const {
        id
      } = request.params;
      const attrs = JSON.parse(request.requestBody);
      attrs.startsAt = new Date(attrs.startsAt);
      const budgetWithSameStartsAt = schema.budgets.findBy(budget => budget.startsAt.getMonth() === attrs.startsAt.getMonth() && budget.startsAt.getFullYear() === attrs.startsAt.getFullYear());
      if (budgetWithSameStartsAt && budgetWithSameStartsAt.id !== id) {
        return new _emberCliMirage.Response(422, {}, {
          errors: {
            startsAt: ['Startdatum ist bereits vorhanden.']
          }
        });
      }
      const model = schema.budgets.find(id);
      return model.update(attrs);
    });
    self.delete('/settings/monthly-fees/budgets/:id', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.budgets.find(id);
      return model.destroy();
    });
  };
  _exports.BudgetSettingRoutes = BudgetSettingRoutes;
  const buildPayload = schema => {
    const today = new Date();
    const budgets = schema.budgets.all();
    const payload = {
      active: null,
      future: [],
      past: []
    };
    budgets.sort((a, b) => {
      return b.startsAt < a.startsAt ? 1 : -1;
    }).models.forEach(budget => {
      if (budget.startsAt <= today) {
        if (!payload.active) {
          payload.active = budget;
        } else if (payload.active.startsAt < budget.startsAt) {
          payload.past.push(payload.active);
          payload.active = budget;
        }
      } else {
        payload.future.push(budget);
      }
    });
    payload.future = payload.future.sort((a, b) => {
      return b.startsAt < a.startsAt ? -1 : 1;
    });
    payload.past = payload.past.sort((a, b) => {
      return b.startsAt < a.startsAt ? -1 : 1;
    });
    return {
      budgets: payload
    };
  };
});