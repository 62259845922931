define("additive-mi/components/ami-insights/filters", ["exports", "@ember/component", "@ember/template-factory", "additive-mi/config/environment", "@additive-apps/ui/components/ui-property-watcher", "@ember/object", "ember-concurrency", "@ember/service", "date-fns", "@additive-apps/ui/utils/date-util", "additive-mi/utils/constants"], function (_exports, _component, _templateFactory, _environment, _uiPropertyWatcher, _object, _emberConcurrency, _service, _dateFns, _dateUtil, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <UiDatepicker
      @label={{t "uiDateFilter.label"}}
      @hasFilters={{true}}
      @defaultFilter={{this._defaultFilter}}
      @maxDate={{this._maxFilterDate}}
      @minDate={{this._minFilterDate}}
      @isRangePicker={{true}}
      @range={{this._initialDate}}
      @onConfirm={{this.onDateChange}}
    />
  </div>
  
  {{#unless this.hideHost}}
    <UiSelect
      @classNames="pl2-sm"
      @label={{t "dashboard.filters.hosts.label"}}
      @placeholder={{t "dashboard.filters.hosts.placeholder"}}
      @isMultiSelect={{true}}
      @options={{this._options}}
      @selected={{this.initialValues}}
      @valueKey="code"
      @objectKey="label"
      @onChange={{this.onSelectChange}}
      @onClose={{this.onSubmitChanges}}
      @onSelectAll={{this.onSubmitChanges}}
      @onDeselectAll={{this.onSubmitChanges}}
      as |optionComponent option|
    >
      <optionComponent.item @value={{option}}>
        {{option.label}}
      </optionComponent.item>
    </UiSelect>
  {{/unless}}
  */
  {
    "id": "zFj4uGEg",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@label\",\"@hasFilters\",\"@defaultFilter\",\"@maxDate\",\"@minDate\",\"@isRangePicker\",\"@range\",\"@onConfirm\"],[[28,[37,1],[\"uiDateFilter.label\"],null],true,[30,0,[\"_defaultFilter\"]],[30,0,[\"_maxFilterDate\"]],[30,0,[\"_minFilterDate\"]],true,[30,0,[\"_initialDate\"]],[30,0,[\"onDateChange\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"hideHost\"]]],[[[1,\"  \"],[8,[39,3],null,[[\"@classNames\",\"@label\",\"@placeholder\",\"@isMultiSelect\",\"@options\",\"@selected\",\"@valueKey\",\"@objectKey\",\"@onChange\",\"@onClose\",\"@onSelectAll\",\"@onDeselectAll\"],[\"pl2-sm\",[28,[37,1],[\"dashboard.filters.hosts.label\"],null],[28,[37,1],[\"dashboard.filters.hosts.placeholder\"],null],true,[30,0,[\"_options\"]],[30,0,[\"initialValues\"]],\"code\",\"label\",[30,0,[\"onSelectChange\"]],[30,0,[\"onSubmitChanges\"]],[30,0,[\"onSubmitChanges\"]],[30,0,[\"onSubmitChanges\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"item\"]],null,[[\"@value\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,2,[\"label\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1,2]]]]],[1,\"\\n\"]],[]],null]],[\"optionComponent\",\"option\"],false,[\"ui-datepicker\",\"t\",\"unless\",\"ui-select\"]]",
    "moduleName": "additive-mi/components/ami-insights/filters.hbs",
    "isStrictMode": false
  });
  /**
   * Component which represents the filters for the insights
   *
   * @class ami-insights/filters
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _uiPropertyWatcher.default.extend({
    amiLocalStorage: (0, _service.inject)(),
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    classNames: ['flex flex-column flex-row-sm'],
    /**
     * whether the host filter is visible
     *
     * @property hideHost
     * @type {Boolean}
     */
    hideHost: false,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     */
    organizationSlug: null,
    /**
     * the maximum filter date
     *
     * @property maxFilterDate
     * @type {Date}
     */
    maxFilterDate: null,
    /**
     * the active filters object
     *
     * @property _activeFilters
     * @type {Object}
     * @default null
     * @private
     */
    _activeFilters: null,
    /**
     * the active organization-specific filters object
     *
     * @property _activeOrganizationFilters
     * @type {Object}
     * @default null
     * @private
     */
    _activeOrganizationFilters: null,
    /**
     * the dateRange object
     *
     * @property _dateRange
     * @type {Object}
     * @private
     */
    _dateRange: null,
    /**
     * the hosts object
     *
     * @property _hosts
     * @type {Object}
     * @private
     */
    _hosts: null,
    /**
     * the minimum filter date
     *
     * @property _minFilterDate
     * @type {Date}
     * @private
     */
    _minFilterDate: null,
    /**
     * the currently loaded options in the filter
     * select
     *
     * @property _options
     * @type {Array}
     * @private
     */
    _options: null,
    /**
     * the default filter
     *
     * @property _defaultFilter
     * @type {String}
     * @private
     */
    _defaultFilter: null,
    _maxFilterDate: (0, _object.computed)('maxFilterDate', {
      get() {
        return this.maxFilterDate || null;
      }
    }),
    /**
     * A filters computed property which will be passed as argument to onChange event
     *
     * @computed _hosts
     * @type {Object}
     * @private
     */
    _filters: (0, _object.computed)('_dateRange', '_defaultFilter', '_hosts.length', 'activeFilters', 'activeOrganizationFilters', {
      get() {
        let filters = Object.assign({}, this.activeFilters, this.activeOrganizationFilters, this._dateRange);
        filters = Object.assign(filters, {
          dateFilter: this._defaultFilter
        });
        if (this._hosts && this._hosts.length > 0) {
          filters = Object.assign(filters, {
            hosts: this._hosts.join(',')
          });
        } else {
          delete filters.hosts;
        }
        return filters;
      }
    }),
    init() {
      this._super(...arguments);
      (0, _object.setProperties)(this, {
        _watchedProperties: ['organizationSlug'],
        _previousorganizationSlug: this.organizationSlug
      });
      this._activeFilters = this.amiLocalStorage.getStoredValue(_constants.LOCAL_STORAGE_FILTER_KEY);
      this._activeOrganizationFilters = this.amiLocalStorage.getValue(`${_constants.LOCAL_STORAGE_FILTER_KEY}-${this.organizationSlug}`);

      // get date filter from query params
      const urlParams = new URLSearchParams(window.location.search);
      const dateFilter = urlParams.get('dateFilter');
      const start = urlParams.get('start');
      const end = urlParams.get('end');
      if (this._activeFilters.start && this._activeFilters.end || start && end) {
        const _dateRange = {
          start: start || this._activeFilters.start,
          end: end || this._activeFilters.end
        };
        if (start && end) this._activeFilters.dateFilter = 'custom_date';
        this.amiLocalStorage.setValue(_constants.LOCAL_STORAGE_FILTER_KEY, {
          start: _dateRange.start,
          end: _dateRange.end,
          dateFilter: typeof this._activeFilters?.dateFilter === 'object' ? this._activeFilters?.dateFilter.value : this._activeFilters?.dateFilter
        });
        this._activeFilters = this.amiLocalStorage.getStoredValue(_constants.LOCAL_STORAGE_FILTER_KEY);
        (0, _object.setProperties)(this, {
          _dateRange,
          _initialDate: {
            ..._dateRange
          }
        });
      }
      if (typeof this._activeFilters?.dateFilter === 'object') this._activeFilters.dateFilter = this._activeFilters?.dateFilter.value;
      dateFilter && !end && !start ? (0, _object.set)(this, '_defaultFilter', dateFilter) : this._activeFilters?.dateFilter ? (0, _object.set)(this, '_defaultFilter', this._activeFilters?.dateFilter) : (0, _object.set)(this, '_defaultFilter', 'last_month');
      if (this._activeOrganizationFilters.hosts) {
        (0, _object.set)(this, '_hosts', typeof this._activeOrganizationFilters.hosts === 'string' ? this._activeOrganizationFilters.hosts.split(',') : this._activeOrganizationFilters.hosts);
      }
      (0, _object.set)(this, '_minFilterDate', new Date(2019, 0, 1));
    },
    _onPropertyChange() {
      const activeFilters = this.amiLocalStorage.getStoredValue(_constants.LOCAL_STORAGE_FILTER_KEY);
      const activeOrganizationFilters = this.amiLocalStorage.getValue(`${_constants.LOCAL_STORAGE_FILTER_KEY}-${this.organizationSlug}`);
      if (activeFilters.start && activeFilters.end) {
        (0, _object.set)(this, '_initialDate', {
          start: (0, _dateUtil.parseDate)(activeFilters.start),
          end: (0, _dateUtil.parseDate)(activeFilters.end)
        });
      } else (0, _object.set)(this, '_defaultFilter', activeFilters.dateFilter);
      (0, _object.setProperties)(this, {
        _hosts: typeof activeOrganizationFilters.hosts === 'string' ? activeOrganizationFilters.hosts.split(',') : activeOrganizationFilters.hosts,
        _options: []
      });
      this._setupFilter.perform();
    },
    /**
     * fetches filter
     *
     * @type {Task}
     * @function _loadFilter
     * @private
     */
    _setupFilter: (0, _emberConcurrency.task)(function* () {
      if (!this.hideHost) {
        const tasks = [];
        const url = `${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/filters`;
        try {
          tasks.push(this.authenticatedFetch.fetch(url));
          tasks.push((0, _emberConcurrency.timeout)(250));
          const [response] = yield (0, _emberConcurrency.all)(tasks);
          if (!response.ok) {
            throw new Error();
          }
          const json = yield response.json();
          if (!json || !json.hosts) {
            throw new Error();
          }
          if (this._activeOrganizationFilters?.hosts) {
            const selectedValues = this._activeOrganizationFilters.hosts.split(',');
            const initialValues = json.hosts.filter(host => selectedValues.indexOf(host.code) >= 0);
            (0, _object.set)(this, 'initialValues', initialValues);
          }
          (0, _object.set)(this, '_options', json.hosts);
        } catch (error) {
          this.uiToast.showToast({
            title: this.intl.t('toast.unexpectedError'),
            type: 'error'
          });
          (0, _object.set)(this, '_options', []);
        }
      }
    }).on('init'),
    /**
     * executed on change of filters
     *
     * @function onChangeFilter
     */
    onChangeFilter() {},
    onDateChange: (0, _object.action)(function (start, end, activeFilter) {
      if (start === this._filters?.start && end === this._filters.end) return;
      if (!start || !end) {
        if (!start && !end) {
          start = this._dateRange?.start;
          end = this._dateRange?.end;
        } else if (!start) {
          const startDate = (0, _dateFns.addDays)(new Date(end), -1);
          start = (0, _dateFns.format)(startDate, 'yyyy-MM-dd');
        } else if (!end) {
          const endDate = (0, _dateFns.addDays)(new Date(start), 1);
          end = (0, _dateFns.format)(endDate, 'yyyy-MM-dd');
        }
      }
      (0, _object.set)(this, '_dateRange', {
        start,
        end
      });
      const filters = this._filters;
      if (typeof activeFilter === 'object') activeFilter = activeFilter.value;
      this.amiLocalStorage.setValue(_constants.LOCAL_STORAGE_FILTER_KEY, {
        start: filters.start,
        end: filters.end,
        dateFilter: activeFilter
      });
      this._activeFilters = this.amiLocalStorage.getStoredValue(_constants.LOCAL_STORAGE_FILTER_KEY);
      let queryParams = ['start', 'end', 'dateFilter', 'conversionType', 'attributionMode', 'hosts', 'hiddenColumnsAnalytics', 'reservationTypes', 'groupingHierarchy', 'groups'];
      if (this.currentUser?.user?.isAdditive || _environment.default.environment !== 'production') queryParams.push('sessionBasis');
      if (this.amiLocalStorage.getStoredValue(_constants.LOCAL_STORAGE_FILTER_KEY).dateFilter === 'custom_date') {
        queryParams = queryParams.filter(queryParam => queryParam !== 'dateFilter');
      } else {
        queryParams = queryParams.filter(queryParam => queryParam !== 'start' && queryParam !== 'end');
      }

      // reset queryParams so unused params are removed
      this.amiLocalStorage.setQueryParams([]);
      // update query params of the route
      this.amiLocalStorage.setQueryParams(queryParams);
      this.onChangeFilter();
    }),
    onSelectChange: (0, _object.action)(function (option, checked) {
      let selected = [...(this._hosts || [])];
      if (checked) {
        selected.indexOf(option.code) < 0 && selected.push(option.code);
      } else {
        selected = selected.filter(code => code !== option.code);
      }
      (0, _object.set)(this, '_hosts', selected);
    }),
    onSubmitChanges: (0, _object.action)(function () {
      this.amiLocalStorage.setValue(_constants.LOCAL_STORAGE_FILTER_KEY, {
        start: this._activeFilters?.start || this._initialDate?.start,
        end: this._activeFilters?.end || this._initialDate?.end
      });
      this.amiLocalStorage.setValue(`${_constants.LOCAL_STORAGE_FILTER_KEY}-${this.organizationSlug}`, {
        hosts: this._filters.hosts
      });
      this.onChangeFilter();
    })
  }));
});