define("additive-mi/serializers/service-cost", ["exports", "additive-mi/serializers/application", "lodash.clonedeep", "additive-mi/utils/currency-serialization"], function (_exports, _application, _lodash, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ServiceCostSerializer extends _application.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      super.normalizeResponse(...arguments);

      // check if payload contains array or single content
      const firstKey = Object.keys(payload)[0];
      const contents = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];

      // transform currency properties
      contents.forEach(content => {
        const value = content?.values[0]?.value || 0;
        content.value = (0, _currencySerialization.centsToAmount)(value);
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
    serialize(snapshot, options) {
      let json = super.serialize(snapshot, options);
      const jsonCopy = (0, _lodash.default)(json);
      jsonCopy.values = [];
      jsonCopy.values.push({
        key: 'base',
        value: (0, _currencySerialization.amountToCents)(jsonCopy.value)
      });
      return jsonCopy;
    }
  }
  _exports.default = ServiceCostSerializer;
});